.Details {
  display: flex;
}

.Slider {
  height: 300px;
}

.SliderThumbs {
  height: 100px;
}

.Slider img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.SliderThumbs img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Media {
  margin-right: 30px;
  width: 400px;
  flex-shrink: 0;
}

.Title {
  font-size: 40px;
}

.Description {
  white-space: pre-wrap;
}

.Subtitle {
  font-size: 23px;
  margin-bottom: 5px;
}

.Info {
  position: relative;
  flex-grow: 1;
}

.Header {
  display: flex;
}

.Titles {
  flex-grow: 1;
}

.TitleMobile {
  display: none;
}

@media (max-width: 1100px) {
  .Header {
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 20px;
  }

  .AddToCart {
    margin-left: 0px;
  }
}

@media (max-width: 850px) {
  .TitleMobile {
    display: block;
  }

  .Titles {
    display: none;
  }

  .Details {
    flex-direction: column;
  }

  .Info {
    margin-top: 20px;
    flex-grow: 0;
  }

  .Media {
    width: 100%;
  }
}
