.PromotionsContainer {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.Promotion{
  width: calc(50% - 15px);
}

@media (max-width: 840px) {
  .PromotionsContainer {
    flex-direction: column;
  }

  .Promotion{
    width: 100%;
    margin-bottom: 30px;
  }
}
