.Heart {
  fill: #c4c4c4;
  width: 30px;
  cursor: pointer;
  transition: 200ms ease-out;
}

.Pulsing {
  animation: pulse 1s ease;
}

.Active {
  fill: #457d58;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.25);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.25);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
