.Search {
  flex-grow: 1;
  position: relative;
  height: 40px;
  display: flex;
}

.Search input {
  font-size: 16px;
  padding-left: 15px;
  padding-right: 60px;
  border: 1px solid #1a582f;
  appearance: none;
  outline: none;
  color: #0c2916;
  width: 100%;
  border-radius: 3px;
}

.Form {
  display: flex;
  height: 100%;
  flex-grow: 1;
}

.SearchResults {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #dedede;
  border-top: 0;
  z-index: 10;
  display: none;
}

.SearchResults.Open {
  display: block;
}

.ShowAdditional {
  padding: 10px;
  display: block;
  color: #457d58;
  text-decoration: none;
  font-weight: bold;
  transition: 100ms ease-out;
}
.ShowAdditional:hover {
  background-color: #457d58;
  color: #fff;
}

@media (max-width: 991px) {
  .Search {
    order: 3;
    width: 100%;
  }
}
