.Logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "Megrim";
  text-decoration: none;
  color: #1a582f;
  padding: 10px 0;
  margin-right: 150px;
}

.Logo p {
  font-size: 28px;
  font-weight: bold;
}

.Logo img {
  height: 50px;
  margin-right: 10px;
  transition: 200ms ease-out;
  transform: scale(1);
}

.Logo:hover img {
  transform: scale(1.1);
}

@media (max-width: 1300px) {
  .Logo {
    margin-right: 50px;
  }
}

@media(max-width: 991px){
  .Logo p {
    font-size: 20px;
  }

  .Logo{
    margin-right: 10px;
  }
}