.SearchResult {
  padding: 10px;
  display: flex;
  border-top: 1px solid #dedede;
  background-color: #fff;
  transition: 100ms ease-out;
}
.SearchResult:hover {
  background-color: #73ac86;
  color: #fff;
}

.SearchResult figure {
  width: 25%;
  margin-right: 10px;
}

.SearchResult figure img {
  width: 100%;
  height: 75px;
  object-fit: contain;
}

.SearchResult div {
  flex-grow: 1;
}

.Link {
  text-decoration: none;
  color: inherit;
}
.DiscountPrice {
  font-size: 15px;
  font-weight: 100;
  color: #666;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}

.DiscountPrice::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: rotate(-15deg);
  width: 100%;
  height: 2px;
  background-color: rgba(255, 100, 100, 0.5);
  content: " ";
}

.Price{
  font-weight: bold;
}
