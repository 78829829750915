.Slide {
  border-left: 1px solid #ddd;
  padding: 50px 16px;
  margin-bottom: 32px;
  transition: 200ms ease-out;
  position: relative;
  text-decoration: none;
}

.First {
  border-left: 0;
}

.Slide:hover {
  background-color: rgba(0, 158, 40, 0.03);
}

.Slide .Percentage {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #457d58;
  border-radius: 5px;
  color: #fff;
  display: none;
  outline: 2px dotted #457d58;
  outline-offset: 1px;
}

.Slide.Discount .Percentage {
  display: block;
}

.Link{
  text-decoration: none;
  color: inherit;
}

.PriceOld {
  font-size: 15px;
  font-weight: 100;
  color: #666;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
}

.PriceOld::after {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: rotate(-15deg);
  width: 100%;
  height: 2px;
  background-color: rgba(255, 100, 100, 0.5);
  content: " ";
}

.Image {
  text-align: center;
  width: 100%;
  height: 180px;
  margin-bottom: 10px;
}

.Image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Slide .Price {
  font-weight: bold;
  color: #564e58;
  display: flex;
}

.Slide .Title {
  font-weight: bold;
  color: #262626;
}

.Slide .Subtitle {
  color: #262626;
}
