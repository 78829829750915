.Registration{
  width: 300px;
  margin: 0 auto;
}

.Form {
  display: flex;
  flex-direction: column;
}

.Form .Input {
  margin: 10px 0;
}
