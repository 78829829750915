.Tag {
  background-color: #457d58;
  transition: 200ms ease-out;
  color: #fff;
  text-decoration: none;
  padding: 5px;
  margin-right: 5px;
  display: inline-block;
  border-radius: 5px;
}

.Tag:hover {
  background-color: #578e6a;
}

.Tags {
  margin-bottom: 20px;
}
