.Input {
}

.Login{
  width: 100%;
}

.Form {
  margin: 0 auto;
  width: 300px;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border: 4px solid #73ac86;
}

.Form .Input {
  width: 100%;
  margin: 10px 0;
}

.Form button a{
  text-decoration: none;
  color: inherit;
}