.Products {
  display: flex;
}

.ProductList {
  display: flex;
  flex-wrap: wrap;
}

.Subcategories {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.Sidenav {
  width: 25%;
  flex-shrink: 0;
}

.Subcategories a {
  text-decoration: none;
  color: #333;
  font-family: "Megrim";
  font-size: 20px;
  display: block;
  font-weight: bold;
  padding-left: 10px;
}

.SelectElements {
  width: 300px;
}

.LinkBig {
  font-size: 25px !important;
}

.Subcategories .Active {
  color: #457d58;
}

.ProductList > a {
  width: calc(100% / 3);
}

.ProductList > a:nth-child(3n + 1) {
  border-left: none;
}

.Filters {
  margin-bottom: 30px;
}

.Title {
  text-transform: capitalize;
  margin-bottom: 10px;
}

.MetaInfo{
  font-size: 12px;
  color: #444;
  text-align: center;
}

@media (max-width: 1200px) {
  .ProductList > a {
    width: 50%;
    border: none;
  }

  .ProductList > a:nth-child(odd) {
    border-right: 1px solid #ddd;
  }
}

@media (max-width: 850px) {
  .Products {
    flex-direction: column;
  }

  .Sidenav {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .ProductList > a {
    width: 100%;
    border: none;
    border-right: none !important;
    border-bottom: 1px solid #ddd;
    margin-bottom: 0;
  }
}
