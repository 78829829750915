.Promotion {
  position: relative;
  text-decoration: none;
  color: #fff;
  font-family: "Megrim";
  font-size: 40px;
  padding: 70px 50px;
  text-align: center;
  line-height: 1.4;
  overflow: hidden;
}

.Promotion p {
  transform: translateY(0);
  transition: 400ms ease-out;
  transition-delay: 100ms;
}

.Promotion span {
  display: block;
  margin: 0 auto;
  height: 1px;
  background-color: #fff;
  width: 0;
  transition: 300ms ease-out;
  transition-delay: 200ms;
}

.Promotion .Large {
  font-size: 60px;
}

.Promotion:hover .Image {
  transform: scale(1.2);
}

.Promotion:hover p {
  transform: translateY(-10px);
}

.Promotion:hover span {
  width: 50px;
}

.Image {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scale(1);
  transition: 400ms ease-out;
  filter: brightness(0.4);
}

@media (max-width: 1000px) {
  .Promotion {
    font-size: 30px;
  }
  .Promotion .Large {
    font-size: 50px;
  }
}