.Burger {
  align-items: center;
  margin-right: 35px;
  color: #1a582f;
  display: none;
  cursor: pointer;
}

.Burger ul {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 23px;
  height: 13px;
  margin-right: 10px;
}

.Burger ul li {
  width: 100%;
  height: 1px;
  background-color: #1a582f;
  transition: 100ms ease-out;
  transform-origin: center;
  position: relative;
  top: 0;
}

.Burger ul.Open li:nth-child(1) {
  transform: rotate(45deg);
  top: 6px;
}

.Burger ul.Open li:nth-child(3) {
  transform: rotate(-45deg);
  top: -6px;
}

.Burger ul.Open li:nth-child(2) {
  transform: translateX(-20px);
  opacity: 0;
}

@media(max-width: 991px){
  .Burger{
    display: flex;
  }
}