.Navigation {
  width: 100%;
}

.Inner {
  transition: 400ms ease-out;
}

.Inner .SubNav {
  padding-left: 20px;
  margin-bottom: 10px;
}

.Inner ul li {
  margin-right: 40px;
}

.Inner ul li a {
  text-decoration: none;
  font-family: "Megrim", cursive;
  text-decoration: none;
  color: #1a582f;
  font-weight: bold;
  font-size: 23px;
  display: block;
  transition: 200ms ease-out;
  padding: 4px 0;
}

.Inner .SubNav li a {
  font-size: 16px;
}

@media (max-width: 991px) {

}
