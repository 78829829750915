.Quote {
  width: 100%;
  font-family: "Megrim";
  color: #fff;
  position: relative;
  padding: 50px;
  margin-bottom: 30px;
  transition: 200ms ease-out;
}

.Quote .Image {
  z-index: -1;
  background-size: cover;
  background-position: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  filter: brightness(0.2);
  animation: fadeInFromBlack 1s 1s ease-out forwards;
}

.Quote p {
  font-size: 55px;
  text-align: center;
  line-height: 1.4;
  padding: 0px 50px;
  opacity: 0;
  animation: fadeInLeft 2s 2s ease-out forwards;
  transition: 200ms ease-out;
}

.Quote span {
  display: block;
  font-size: 35px;
  text-align: right;
  margin-top: 30px;
  opacity: 0;
  animation: fadeInLeft 2s 4s ease-out forwards;
  transition: 200ms ease-out;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromBlack {
  0% {
    filter: brightness(0.2);
  }

  100% {
    filter: brightness(0.5);
  }
}

@media (max-width: 1000px) {
  .Quote p {
    font-size: 45px;
  }

  .Quote span {
    font-size: 30px;
    margin-top: 20px;
  }

  .Quote {
    padding: 30px;
  }
}

@media (max-width: 825px) {
  .Quote p {
    font-size: 30px;
  }

  .Quote span {
    font-size: 20px;
    margin-top: 10px;
  }

  .Quote {
    padding: 20px;
  }
}
