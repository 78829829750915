.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.SliderThumbs {
  box-sizing: border-box;
  padding: 10px 0;
}

.SliderThumbs .swiper-slide {
  opacity: 0.4;
}

.SliderThumbs .swiper-slide-thumb-active {
  opacity: 1;
}