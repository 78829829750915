.Header header {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 2px 0px,
    rgba(0, 0, 0, 0.08) 0px 4px 8px 0px;
  border-top: 7px solid #1a582f;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.Header .Inner {
  max-width: 1920px;
  width: calc(100% - 60px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 95px;
}

.Controls {
  flex-shrink: 0;
  margin-left: 150px;
}

.Controls svg {
  height: 40px;
  cursor: pointer;
}

.Controls > svg {
  margin-left: 20px;
}

.Controls svg path {
  transition: 200ms ease-out;
  stroke: #1a582f;
  fill: transparent;
}

.Controls svg:hover path {
  stroke: #73ac86;
}

.MobileOnly {
  display: none;
}

@media (max-width: 1300px) {
  .Controls {
    margin-left: 50px;
  }
}

@media (max-width: 991px) {
  .Header .Inner {
    flex-wrap: wrap;
    height: auto;
    padding: 10px 0 20px 0;
  }

  .Controls {
    margin-left: 10px;
  }

  .Controls svg {
    height: 35px;
    cursor: pointer;
  }

  .Controls > svg {
    margin-left: 10px;
  }
}
