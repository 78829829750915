.AddToCart {
  display: flex;
  height: 50px;
  background-color: #457d58;
  flex-shrink: 0;
  margin-left: 20px;
}

.AddToCart button {
  color: #fff;
  appearance: none;
  border: 0;
  padding: 10px;
  background-color: transparent;
  font-family: "Megrim";
  font-size: 20px;
}

.AddToCart button:hover {
  background-color: #578e6a;
}

.AddToCart input {
  color: #fff;
  border: none;
  background-color: transparent;
  width: 50px;
  outline: none;
  font-size: 20px;
  text-align: center;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.AddToCart div {
  display: flex;
  flex-direction: column;
}

.AddToCart div span {
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  padding: 5px;
  flex-shrink: 1;
  height: 50%;
  line-height: 0.5;
  text-align: center;
}

.AddToCart div span:hover {
  background-color: #578e6a;
}

.AddToCart div span:first-of-type {
  border-bottom: 1px solid #fff;
}
