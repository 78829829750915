@import url("https://fonts.googleapis.com/css2?family=Megrim&display=swap");

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.NavSection {
  width: 300px;
}

.Main {
  max-width: 1920px;
  width: calc(100% - 60px);
  margin: 0 auto;
  min-height: 100vh;
  padding: 130px 0 30px 0;
  display: flex;
}

.MainSection {
  width: calc(100% - 300px);
}

.swiper-button-next,
.swiper-button-prev {
  color: #457d58 !important;
}

.swiper-pagination-bullet-active {
  background-color: #457d58 !important;
}

h1,
h2,
h3 {
  font-family: "Megrim";
}

@media (max-width: 991px) {
  .NavSection {
    position: fixed;
    top: 147px;
    right: 100%;
    background-color: #fff;
    width: 100vw;
    padding: 10px 30px;
    height: calc(100vh - 147px);
    z-index: 9;
    transition: 200ms ease-out;
    transform: translateX(0);
  }

  .NavSection.Open {
    transform: translateX(100%);
  }

  .Main {
    padding-top: 180px;
  }

  .MainSection {
    width: 100%;
  }
}
