.Slider {
  margin-bottom: 30px;
}

.Title {
  font-family: "Megrim";
  font-size: 38px;
  margin-bottom: 15px;
  color: #262626;
  font-weight: normal;
}
